import { Col, Row } from "@/styles/layout";
import { Body } from "@/styles/typography";
import Head from "next/head";
import { ReactNode, useMemo } from "react";
import { useRouter } from "next/router";
import Loader from "@/components/Loader";
import Image from "next/image";
import MyPopover from "@/components/Popover";
import ProfileMenu from "@/components/ProfileMenu";
import { FaChevronDown } from "react-icons/fa";
import { Account } from "@/types/models/Account";
import { useMe } from "@/hooks/useMe";
import { getMediaCdnUrl } from "@/lib/cdn";
import Avatar from "@/components/Avatar";

interface PageProps {
  title: string;
  description?: string;
  charity?: Account;
  noAuth?: boolean;
  children: ReactNode;
}
const Page = ({ title, description, charity, noAuth, children }: PageProps) => {
  const { user, isLoading } = useMe();
  const logo = useMemo(() => {
    if (!charity) return null;
    return getMediaCdnUrl(charity.id, "logo", charity.branding?.logoUrl);
  }, [charity]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={logo ?? "/favicon.ico"} />
      </Head>
      <main>
        <Row
          width="100vw"
          minHeight="100vh"
          maxHeight="100vh"
          bg="gray.2"
          p="16px"
        >
          <Col
            alignItems="stretch"
            flexGrow="1"
            mx={["0px", "16px"]}
            overflow="auto"
          >
            <Row
              height="88px"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Row
                height="77px"
                mx={["0px", "16px"]}
                alignItems="center"
                justifyContent={"flex-start"}
              >
                <Row alignItems="center">
                  <div className="relative w-[100px] md:w-[240px] h-[64px] md:h-[88px]">
                    {logo && (
                      <Image
                        src={logo}
                        style={{ objectFit: "contain", objectPosition: "left" }}
                        fill
                        alt={`${charity?.name ?? "GoGive"} logo`}
                      />
                    )}
                  </div>
                </Row>
              </Row>
              <MyPopover
                trigger={
                  <div className="cursor-pointer">
                    <Avatar />
                  </div>
                }
                content={<ProfileMenu />}
              />
            </Row>
            <Col
              alignItems="center"
              flexGrow="1"
              py="24px"
              px={["0px", "24px"]}
            >
              <Col maxWidth="900px" width="100%" flexGrow="1">
                {isLoading ? (
                  <Col
                    color="primary.textHigh"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Loader size={32} />
                  </Col>
                ) : (
                  children
                )}
              </Col>
            </Col>
          </Col>
        </Row>
      </main>
    </>
  );
};

export default Page;
