import { useAuth } from "@/hooks/auth.provider";
import { useMe } from "@/hooks/useMe";
import { Col } from "@/styles/layout";
import { Heading } from "@/styles/typography";
import { Avatar as AvatarUI } from "./ui/avatar";
import { getAvatarUrl } from "@/lib/cdn";

const Avatar = () => {
  const { authenticated } = useAuth();
  const { user } = useMe(!!authenticated);

  return (
    <>
      {!!user && (
        <AvatarUI
          firstName={user.firstName}
          lastName={user.lastName}
          url={getAvatarUrl(user, "small")!}
        />
      )}
    </>
  );
};

export default Avatar;
