import { Col, Div, Row } from "@/styles/layout";
import { Body } from "@/styles/typography";
import Avatar from "./Avatar";
import { FiHelpCircle, FiLogOut } from "react-icons/fi";
import Hoverable from "@/components/Hoverable";
import { useMe } from "@/hooks/useMe";
import { useAuth } from "@/hooks/auth.provider";
import Link from "next/link";
import { LucideHelpCircle, LucideSettings } from "lucide-react";
import { Button } from "./ui/button";

const ProfileMenu = () => {
  const { logout } = useAuth();
  const { user } = useMe();
  return (
    <div className="flex flex-col p-6 bg-background mx-6 border rounded border-primary space-y-6">
      <div className="flex items-center space-x2">
        <Avatar />
        <Col ml="16px">
          <Body fontSize="14px" fontWeight="600">
            {user?.firstName} {user?.lastName}
          </Body>
          <Body fontSize="14px" fontWeight="300">
            {user?.email}
          </Body>
        </Col>
      </div>
      <div className="space-y-0 ">
        <div>
          <Button
            variant="link"
            className="m-0 p-0 h-fit text-foreground"
            asChild
          >
            <Link href="/settings">
              <div className="flex items-center space-x-4">
                <LucideSettings size={18} />
                <div className="text-sm ">Settings</div>
              </div>
            </Link>
          </Button>
        </div>
        <div>
          <Button
            variant="link"
            className="m-0 p-0 h-fit text-foreground"
            asChild
          >
            <Link href="mailto:admin@gogive.com.au">
              <div className="flex items-center space-x-4">
                <LucideHelpCircle size={18} />
                <div className="text-sm ">Need help?</div>
              </div>
            </Link>
          </Button>
        </div>
      </div>
      <Button variant="destructive-outline" onClick={() => logout()}>
        Log out
      </Button>
    </div>
  );
};

export default ProfileMenu;
