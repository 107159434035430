import { API, API_V2 } from "@/config/api";
import { Donations } from "@/types/models/Donations";
import { RecurringPlan } from "@/types/models/RecurringPlan";
import apiRequest from "./api";

export const routes = {
  list: `${API_V2}/donations`,
  get: (id: string | number) => `${API_V2}/donations/${id}`,
  annualReport: `${API_V2}/donations/annual-report`,
};

export interface DonationsRequest {
  page: number;
  limit: number;
  sortBy: "createdAt" | "grossAmount";
  sortDirection: "ASC" | "DESC";
  accountId?: string;
}

export interface DonationsResponse {
  result: Donations[];
  total: number;
  page: number;
  pages: number;
}

export interface DownloadAnnualReportRequest extends Record<string, string> {
  accountId: string;
}

export const downloadAnnualReport = (data: DownloadAnnualReportRequest) =>
  apiRequest<Buffer>(routes.annualReport, {
    method: "GET",
    params: data,
    responseType: "blob",
    noAuth: false,
  });
